import { useContext } from "react";
import {
  Box,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Center,
  Image,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import DisplayContext from "../context/display-context";
import { wallpaper, openBook, tapeMeasure, ladder, leaves } from "../assets";

export function Service() {
  const { isLargerThanLG, isSmallerThanMD } = useContext(DisplayContext);

  const getSize = () => {
    if (isSmallerThanMD) return 1;
    else if (isLargerThanLG) return 4;
    else return 2;
  };

  return (
    <Box
      id="services"
      px={isLargerThanLG ? "16" : "6"}
      py="16"
      pos="relative"
      _before={{
        content: '""',
        bgImage: leaves,
        bgSize: "100% 100%",
        bgPosition: "center",
        bgAttachment: "fixed",
        pos: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        opacity: 0.4,
        zIndex: -1,
      }}
    >
      <Center>
        <Heading as="u" textUnderlineOffset={10} size="2xl" mb="10">
          Services
        </Heading>
      </Center>
      <Grid
        templateColumns={`repeat(${getSize()}, 1fr)`}
        gridGap={5}
        justifyContent={"space-evenly"}
      >
        <GridItem bgColor={"gray.200"}>
          <Card h={"320px"}>
            <CardHeader>
              <Center>
                <Heading size="md" textAlign={"center"}>
                  Wallcover and Mural Supplies
                </Heading>
              </Center>
            </CardHeader>
            <CardBody>
              <Center>
                <Image boxSize={200} src={wallpaper} />
              </Center>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem bgColor={"gray.200"}>
          <Card h={"320px"}>
            <CardHeader>
              <Center>
                <Heading size="md" textAlign={"center"}>
                  Huge Collection of Wallpapers and Murals
                </Heading>
              </Center>
            </CardHeader>
            <CardBody>
              <Center>
                <Image boxSize={200} src={openBook} />
              </Center>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem bgColor={"gray.200"}>
          <Card h={"320px"}>
            <CardHeader>
              <Center>
                <Heading size="md" textAlign={"center"}>
                  Measurements
                </Heading>
              </Center>
            </CardHeader>
            <CardBody>
              <Center>
                <Image boxSize={200} src={tapeMeasure} />
              </Center>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem bgColor={"gray.200"}>
          <Card h={"320px"}>
            <CardHeader>
              <Center>
                <Heading size="md" textAlign={"center"}>
                  Installation and Removals
                </Heading>
              </Center>
            </CardHeader>
            <CardBody>
              <Center>
                <Image boxSize={200} src={ladder} />
              </Center>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
}
