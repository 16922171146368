import { useContext } from "react";
import { Box, Spacer, Text, Center, Heading } from "@chakra-ui/react";
import DisplayContext from "../context/display-context";

export function About() {
  const { isLargerThanLG } = useContext(DisplayContext);
  return (
    <Box
      id="about"
      px={isLargerThanLG ? "16" : "6"}
      py="16"
      bgColor={"gray.200"}
    >
      <Center>
        <Heading mb="6" as={"h2"} lineHeight="tall">
          Beautify your empty wall with unique custom design murals and
          wallpapers
        </Heading>
      </Center>
      <Box>
        <Text mb="6" fontSize={isLargerThanLG ? "xl" : "base"}>
          At Unique Wallcovering, we believe that your home or business should
          reflect your personal style and vision. We specialize in the
          installation of high-quality wallpaper, and murals. With years of
          experience in the industry, our team of skilled professionals is
          equipped to handle projects of any size and complexity from small
          residential rooms to large commercial spaces.
        </Text>

        <Text mb="6" fontSize={isLargerThanLG ? "xl" : "base"}>
          Getting a good finish is imperative in achieving a stunning and
          dramatic look. Whether you are looking to add a pop of color to a
          single room or want to transform the entire interior of your home or
          business, we have the expertise and experience to make your vision a
          reality. We are committed to providing our clients with the highest
          level of service and quality. We use only the finest materials and the
          latest installation techniques to ensure that your wallpaper or mural
          looks beautiful and lasts for years to come.
        </Text>

        <Text mb="6" fontSize={isLargerThanLG ? "xl" : "base"}>
          If you are interested in hiring us for your wallpaper or mural
          installation or removal project, please don't hesitate to contact us.
          We would be happy to provide you with a free estimate and discuss your
          options.
        </Text>
      </Box>
      <Spacer />
    </Box>
  );
}
