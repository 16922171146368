import {
  Center,
  Link,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  InputGroup,
  InputLeftAddon,
  Input,
  Textarea,
  useToast,
  Tag,
  Heading,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import DisplayContext from "../context/display-context";
import { DynamicStack } from "./common/DynamicStack";
import { leaves } from "../assets";

export function ContactUs() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const { isLargerThanLG } = useContext(DisplayContext);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSubmitForm = async (data: any) => {
    setIsLoading(true);
    setIsError(false);
    try {
      const res = await fetch("/email", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        reset({ name: "", phoneNumber: "", emailAddress: "", details: "" });
        toast({
          title: "Message sent! 🚀",
          description: "Thank you for contacting us!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setIsError(true);
      }
    } catch (err) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return (
    <Box
      id="contact-us"
      px={isLargerThanLG ? "16" : "6"}
      py="16"
      pos="relative"
      _before={{
        content: '""',
        bgImage: leaves,
        bgSize: "100% 100%",
        bgPosition: "center",
        bgAttachment: "fixed",
        pos: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        opacity: 0.2,
        zIndex: -1,
      }}
    >
      <Center>
        <Heading as="u" textUnderlineOffset={10} size="2xl" mb="10">
          Contact Us
        </Heading>
      </Center>
      <Center mb="5">
        <DynamicStack>
          <Tag size={"lg"} variant="solid" colorScheme={"blue"}>
            <Box>
              <Link href="tel:587-436-3494">
                <PhoneIcon />
                {"      "} 587-436-3494
              </Link>
            </Box>
          </Tag>
          <Tag size={"lg"} variant="solid" colorScheme="blue">
            <Box>
              <Link href="mailto:uniquewallcovering@outlook.com" isExternal>
                <EmailIcon />
                {"      "} uniquewallcovering@outlook
              </Link>
            </Box>
          </Tag>
        </DynamicStack>
      </Center>
      <Center>
        <Box w="90%">
          {isError && (
            <Alert variant={"subtle"} status="error" mb={4}>
              <AlertIcon />
              There was an error processing your request. Please contact us at
              587-436-3494
            </Alert>
          )}
          <FormControl isRequired isInvalid={Boolean(errors?.name)}>
            <FormLabel fontSize={"lg"}>Full Name</FormLabel>
            <Input
              size={"lg"}
              placeholder="name"
              type="text"
              {...register("name", {
                required: "Name is a required field",
                pattern: {
                  value: /^[a-zA-Z0-9]/,
                  message: "Invalid name",
                },
              })}
            />
            <FormErrorMessage>
              {errors?.name?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            mt={3}
            isInvalid={Boolean(errors?.phoneNumber)}
          >
            <FormLabel fontSize={"lg"}>Phone Number</FormLabel>
            <InputGroup size={"lg"}>
              <InputLeftAddon children="+1" />
              <Input
                type="tel"
                placeholder="phone number"
                {...register("phoneNumber", {
                  required: "Phone number is a required field",
                  pattern: {
                    value:
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                    message: "Invalid Phone number.",
                  },
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors?.phoneNumber?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            mt={3}
            isInvalid={Boolean(errors?.emailAddress)}
          >
            <FormLabel fontSize={"lg"}>Email address</FormLabel>
            <Input
              size={"lg"}
              placeholder="email address"
              type="email"
              {...register("emailAddress", {
                required: "Email is a required field",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Invalid Email Address",
                },
              })}
            />
            <FormErrorMessage>
              {errors?.emailAddress?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
          <FormControl mt={3}>
            <FormLabel fontSize={"lg"}>Details</FormLabel>
            <Textarea
              size={"lg"}
              placeholder="How can we help you?"
              {...register("details")}
            />
          </FormControl>
          <Button
            isLoading={isLoading}
            mt={5}
            colorScheme="blue"
            as={"b"}
            fontSize={"xl"}
            fontWeight={600}
            width="200px"
            type="submit"
            onClick={handleSubmit(onSubmitForm)}
          >
            Submit
          </Button>
        </Box>
      </Center>
    </Box>
  );
}
