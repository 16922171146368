import {
  Center,
  Image,
  Box,
  Grid,
  GridItem,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useContext, useEffect, useState } from "react";
import DisplayContext from "../context/display-context";

export function OurWork() {
  const { isLargerThanLG } = useContext(DisplayContext);
  const [loadMore, setLoadMore] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch("image")
      .then((response) => {
        return response.json();
      })
      .then((imageUrls) => {
        setImages(imageUrls.data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  return (
    <Box
      id="our-work"
      px={isLargerThanLG ? "16" : "6"}
      py="16"
      bgColor={"gray.200"}
    >
      <Center mb="10">
        <Heading as="u" textUnderlineOffset={10} size="2xl" mb="10">
          Our Work
        </Heading>
      </Center>
      <Grid templateColumns={`repeat(${isLargerThanLG ? 3 : 1}, 1fr)`} gap={3}>
        {images
          .slice(0, !loadMore ? 6 : images.length)
          .map((image: string, idx) => {
            return (
              <GridItem key={idx} overflow="hidden" boxShadow={"xl"}>
                <Image
                  _hover={{
                    transform: "scale(1.1)",
                    transformOrigin: "50% 50%",
                  }}
                  transition="transform 0.6s"
                  w={"100%"}
                  h={"100%"}
                  src={image}
                />
              </GridItem>
            );
          })}
      </Grid>
      <Center mt={10}>
        <IconButton
          bgColor={"gray.200"}
          _hover={{
            bgColor: "gray.100",
          }}
          icon={
            loadMore ? (
              <ChevronUpIcon fontSize="4.25rem" />
            ) : (
              <ChevronDownIcon fontSize="4.25rem" />
            )
          }
          aria-label="Facebook"
          onClick={() => {
            setLoadMore(!loadMore);
          }}
        >
          Load More
        </IconButton>
      </Center>
    </Box>
  );
}
