const IMAGEKIT_URL = "https://ik.imagekit.io/3ljmdxl94/";

export const logo = "logo.svg";
export const navBarLogo = "navbar-logo.svg";
export const background = `${IMAGEKIT_URL}background.jpg`;
export const training = `${IMAGEKIT_URL}training.png`;
export const longPaperRoll = `${IMAGEKIT_URL}long-paper-roll.png`;
export const wallpaper = `${IMAGEKIT_URL}wallpaper.png`;
export const openBook = `${IMAGEKIT_URL}open-book.png`;
export const tapeMeasure = `${IMAGEKIT_URL}tape-measure.png`;
export const ladder = `${IMAGEKIT_URL}ladder.png`;
export const leaves = `${IMAGEKIT_URL}leaves.png`;
export const wood = `${IMAGEKIT_URL}wood.jpg`;
