import {
  Divider,
  IconButton,
  Stack,
  Text,
  Image,
  Center,
  Box,
} from "@chakra-ui/react";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaPhone,
  FaTiktok,
} from "react-icons/fa";
import { logo } from "../assets";
import { useContext } from "react";
import DisplayContext from "../context/display-context";
import { facebookLink, instagramLink, tiktokLink } from "../constants";

export const Footer = () => {
  const { isLargerThanLG } = useContext(DisplayContext);

  return (
    <Box
      id="footer"
      px={isLargerThanLG ? "16" : "6"}
      pt="16"
      pb="5"
      bgColor={"gray.100"}
    >
      <Stack
        direction={{ base: "column", lg: "row" }}
        py={{ base: "4", md: "8" }}
        justifyContent={"space-between"}
        align={"center"}
        px={"45"}
        spacing={"10"}
      >
        <Image minW={"250px"} w={"400px"} src={require(`../assets/${logo}`)} />

        <Stack
          direction={{ base: "column-reverse", md: "column" }}
          spacing={{ base: "12", md: "8" }}
        >
          <Stack direction={{ base: "column", md: "row" }} spacing="8">
            <Stack spacing="4" minW="48">
              <Text fontSize="lg" fontWeight="extrabold" textAlign={"center"}>
                Hours
              </Text>
              <Stack align={"center"}>
                <Text fontSize="sm">Monday 9AM - 6PM</Text>
                <Text fontSize="sm">Tuesday 9AM - 6PM</Text>
                <Text fontSize="sm">Wednesday 9AM - 6PM</Text>
                <Text fontSize="sm">Thursday 9AM - 6PM</Text>
                <Text fontSize="sm">Friday 9AM - 6PM</Text>
              </Stack>
            </Stack>
            <Stack spacing="4" minW="48">
              <Text fontSize="lg" fontWeight="extrabold" textAlign={"center"}>
                Locations Served
              </Text>
              <Stack align={"center"}>
                <Text fontSize="sm">Calgary, AB</Text>
                <Text fontSize="sm">Airdrie, AB</Text>
                <Text fontSize="sm">Chestermere, AB</Text>
                <Text fontSize="sm">Cochrane, AB</Text>
              </Stack>
            </Stack>
            <Stack spacing="4" minW="48" align={"center"}>
              <Text fontSize="lg" fontWeight="extrabold">
                Follow us on
              </Text>
              <Stack direction="row">
                <IconButton
                  as="a"
                  href={instagramLink}
                  target={"_blank"}
                  aria-label="Instagram"
                  icon={<FaInstagram fontSize="1.25rem" />}
                />
                <IconButton
                  as="a"
                  href={facebookLink}
                  aria-label="Facebook"
                  target={"_blank"}
                  icon={<FaFacebook fontSize="1.25rem" />}
                />
                <IconButton
                  as="a"
                  href={tiktokLink}
                  aria-label="Tiktok"
                  target={"_blank"}
                  icon={<FaTiktok fontSize="1.25rem" />}
                />
              </Stack>
            </Stack>
            <Stack spacing="4" minW="48" align={"center"}>
              <Text fontSize="lg" fontWeight="extrabold">
                Contact us at
              </Text>
              <Stack direction="row">
                <IconButton
                  as="a"
                  href="tel:587-436-3494"
                  aria-label="Phone"
                  icon={<FaPhone fontSize="1.25rem" />}
                />
                <IconButton
                  as="a"
                  href="mailto:uniquewallcovering@outlook.com"
                  aria-label="Email"
                  icon={<FaEnvelope fontSize="1.25rem" />}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Center mt="5">
        <Text fontSize="sm" color="subtle" textAlign={"center"}>
          &copy; {new Date().getFullYear()} Unique Wallcovering All rights
          reserved.
        </Text>
      </Center>
    </Box>
  );
};
