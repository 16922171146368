import { useContext } from "react";
import { Box, Text, Center, Link, Stack } from "@chakra-ui/react";
import DisplayContext from "../context/display-context";

export function Review() {
  const { isLargerThanLG } = useContext(DisplayContext);
  return (
    <Box
      id="reviews"
      px={isLargerThanLG ? "16" : "6"}
      py="16"
      bgColor={"gray.500"}
    >
      <Center>
        <Stack direction={"row"}>
          <Link
            color="white"
            href={"https://g.page/r/CVevTzy91VGNEBM/review"}
            isExternal
          >
            <Text fontSize="2xl" color={"white"}>
              Submit a review or see what our customers has to say about their
              experience with us at <Text as="u">Google Reviews</Text>
            </Text>
          </Link>
        </Stack>
      </Center>
    </Box>
  );
}
