import { useContext } from "react";
import { Box, Center, IconButton, Image, Stack } from "@chakra-ui/react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { logo, background, wood } from "../assets";
import DisplayContext from "../context/display-context";
import { instagramLink, facebookLink } from "../constants";

export function Home() {
  const { isSmallerThanSM } = useContext(DisplayContext);
  return (
    <Box
      id="home"
      mt={"28px"}
      py="16"
      pos="relative"
      height={"80vh"}
      _before={{
        content: '""',
        bgImage: isSmallerThanSM ? wood : background,
        bgSize: isSmallerThanSM ? "100% 100%" : "cover",
        bgPosition: "center",
        bgAttachment: "fixed",
        pos: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        opacity: 0.7,
      }}
    >
      <Center position="absolute" top="35%">
        <Stack
          direction={"column"}
          align="center"
          spacing={5}
          justifyContent={"space-between"}
        >
          <Image minW="300px" w="50%" src={require(`../assets/${logo}`)} />
          <Stack direction="row" justifyContent={"space-between"} spacing={15}>
            <IconButton
              as="a"
              href={facebookLink}
              variant="outline"
              aria-label="Facebook"
              colorScheme={"facebook"}
              icon={<FaFacebook fontSize="1.25rem" />}
              target={"_blank"}
            />
            <IconButton
              as="a"
              href={instagramLink}
              variant="outline"
              aria-label="Instagram"
              colorScheme={"pink"}
              icon={<FaInstagram fontSize="1.25rem" />}
              target={"_blank"}
            />
          </Stack>
        </Stack>
      </Center>
    </Box>
  );
}
