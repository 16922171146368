import {
  Home,
  Service,
  OurWork,
  WithSubnavigation,
  ContactUs,
  About,
  Review,
  Footer,
} from "../components";

export default function Main() {
  return (
    <>
      <WithSubnavigation />
      <Home />
      <About />
      <Service />
      <OurWork />
      <ContactUs />
      <Review />
      <Footer />
    </>
  );
}
