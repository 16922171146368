import { HStack, VStack } from "@chakra-ui/react";
import DisplayContext from "../../context/display-context";
import { useContext } from "react";

export const DynamicStack: React.FC<{
  children: React.ReactNode;
  spacing?: string;
}> = ({ children, spacing }) => {
  const { isLargerThanLG } = useContext(DisplayContext);
  return isLargerThanLG ? (
    <HStack spacing={spacing}>{children}</HStack>
  ) : (
    <VStack spacing={spacing}>{children}</VStack>
  );
};
