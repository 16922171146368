import React from "react";
import { useMediaQuery } from "@chakra-ui/react";

const DisplayContext = React.createContext({
  isLargerThanLG: false,
  isSmallerThanMD: false,
  isSmallerThanSM: false,
});

export const DisplayContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const breakpoints = {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  };
  const [isLargerThanLG] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [isSmallerThanMD] = useMediaQuery(`(max-width: ${breakpoints.md})`);
  const [isSmallerThanSM] = useMediaQuery(`(max-width: ${breakpoints.sm})`);
  return (
    <DisplayContext.Provider
      value={{ isLargerThanLG, isSmallerThanMD, isSmallerThanSM }}
    >
      {children}
    </DisplayContext.Provider>
  );
};

export default DisplayContext;
